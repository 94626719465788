import { format } from 'date-fns';
import * as CONSTANT from './../Utils/Constants';
import IsLowThinContent from '../IsLowThinContent';
import { BillingsResponse } from '../../types/common.type';
import { ColumnsReturnType, DomainBulkResponse, DomainIdeasResponse, RenderCellTable } from '../../types';
import {
  classNames, formatAmount, formatCurrencyByLocation, formatDateNow, formatNumber, roundNumberWithDecimals, truncate
} from './Utils';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Button, Chip, Stack, Typography } from '@mui/material';

// COMPONENTS
import SerpUrl from '../SerpUrl/SerpUrl';
import LinkRedirect from '../Links/Redirect';
import KeywordLinkBlank from '../KeywordLinkBlank';
import KeywordLink from '../../components/KeywordLink';
import ResultRankAbsolute from '../ResultRankAbsolute';
import KeywordRedirectLink from '../KeywordRedirectLink';
import ContainerIntents from '../KeywordIntent/Container';
import BacklinkPageFromTitle from '../Backlinks/PageFromTitle';
import ContainsKeyword from '../ContainsKeyword/ContainsKeyword';
import KeywordFeatures from '../KeywordFeatures/KeywordFeatures';
import KeywordDifficulty from '../../components/KeywordDifficulty';
import HistoryTrendChart from '../../components/HistoryTrendChart';
import CannibalizeResults from '../CannibalizeResults/CannibalizeResults';
import KeywordPosition from '../../components/KeywordPosition/KeywordPosition';
import OverviewRedirectLink from '../OverviewRedirectLink/KeywordRedirectLink';
import RankRangesWrapper from '../Recharts/RankRangesWrapper/RankRangesWrapper';
import '../../pages/domain/Comparator.scss';

import * as COLUMN from './TableColumnConstants'


//TODO: delete this rubbish
const formatPosition = (position) => {
  if (position % 1 === 0) {
    return position.toFixed(0);
  } else if ((position * 100) % 1 === 0) {
    return position.toFixed(1);
  }
  return position.toFixed(2);
};



// ******************** KEYWORDS ********************
export const COLUMNS_KEYWORD_ANALYSIS: ColumnsReturnType = (t, options) => [
  COLUMN.COLUMN_KEYWORD_KEYWORD(t, options),
  COLUMN.COLUMN_KEYWORD_INFO_SEARCH_VOLUME(t, options),
  COLUMN.COLUMN_KEYWORD_INFO_CPC(t, options),
  COLUMN.COLUMN_KEYWORD_INFO_COMPETITION(t, options),
  COLUMN.COLUMN_EXTRA_KEYWORD_DIFFICULTY(t, options),
  COLUMN.COLUMN_SEARCH_INTENT_INFO(t, options),
  COLUMN.COLUMN_SERP_INFO(t, options),
  COLUMN.COLUMN_KEYWORD_INFO_HISTORY(t, options)
];

export const COLUMNS_KEYWORD_SERP_OVERVIEW: ColumnsReturnType = (t, options) => [
  COLUMN.COLUMN_RANK_ABSOLUTE(t, options),
  COLUMN.COLUMN_URL(t, options),
  COLUMN.COLUMN_TRAFFIC_COST(t, options),
  COLUMN.COLUMN_ETV(t, options)
];


// ******************** DOMAINS ********************
export const COLUMNS_DOMAIN_KEYWORDS: ColumnsReturnType = (t, options) => [
  COLUMN.COLUMN_KEYWORD_KEYWORD(t, options),
  COLUMN.COLUMN_SEARCH_INTENT_INFO(t, options),
  COLUMN.COLUMN_SERP_INFO_SERP_POSITION(t, options),
  COLUMN.COLUMN_TRAFFIC(t, options),
  COLUMN.COLUMN_KEYWORD_INFO_SEARCH_VOLUME(t, options),
  COLUMN.COLUMN_EXTRA_KEYWORD_DIFFICULTY(t, options),
  COLUMN.COLUMN_KEYWORD_INFO_CPC(t, options),
  COLUMN.COLUMN_SERP_INFO_SERP_URL(t, options),
  COLUMN.COLUMN_PRICE(t, options),
  COLUMN.COLUMN_KEYWORD_INFO_COMPETITION(t, options)
];


export const COLUMNS_DOMAIN_COMPETITORS_KEYWORDS: ColumnsReturnType = (t, options) => [{
  field: 'domain',
  headerName: t('domain'),
  description: t('tooltips.domains.competitor_domain'),
  flex: 1,
  renderCell: (params) => (<a href={`https://${params.row.domain}`} target="_blank">
      {params.row.domain}
    </a>), ...options
}, {
  field: 'common_keywords',
  headerName: t('common_keywords'),
  description: t('tooltips.domains.competitor_common_keyword'),
  type: 'number',
  headerAlign: 'right',
  minWidth: 200,
  renderCell: (params) => formatAmount(params.row[params.field]), ...options
}, {
  field: 'traffic',
  headerName: t('traffic'),
  description: t('tooltips.domains.competitor_traffic'),
  type: 'number',
  headerAlign: 'right',
  renderCell: (params) => `${formatAmount(params.row[params.field])}`, ...options
}, COLUMN.COLUMN_TRAFFIC_COST(t, options),
];

export const COLUMNS_DOMAIN_BULK_ANALYSIS: ColumnsReturnType<DomainBulkResponse['items'][0]> = (t, op) => [{
  field: 'main_domain',
  headerName: t('domain'),
  description: t('tooltips.tables.keyword'),
  flex: 1,
  width: 100,
  minWidth: 50,
  type: 'string',
  headerAlign: 'center',
  valueGetter: (value, row) => row.main_domain,
  renderCell: (params) => <LinkRedirect location={op?.code} url={params.row.main_domain} />
}, {
  field: 'total_traffic',
  headerName: t('traffic'),
  type: 'number',
  minWidth: 70,
  headerAlign: 'center',
  description: t('tooltips.tables.volumen'),
  valueGetter: (params) => params.row.total_traffic ? params.row.total_traffic.toFixed(2) : '—',
  renderCell: (params) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      {params.value}
    </div>)
}, {
  field: 'total_traffic_cost',
  headerName: t('traffic_cost'),
  type: 'number',
  width: 150,
  headerAlign: 'center',
  description: t('tooltips.tables.volumen'),
  valueGetter: (params) => params.row.total_traffic_cost ? `$${params.row.total_traffic_cost.toFixed(2)}` : '—',
  renderCell: (params) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      {params.value}
    </div>),
  valueFormatter: (params) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency', currency: 'USD', minimumFractionDigits: 2
    }).format(params.row.total_traffic_cost);
  }
}, {
  field: 'keywords_count',
  headerName: t('keywords_count'),
  description: t('tooltips.tables.keyword'),
  width: 200,
  type: 'number',
  headerAlign: 'center',
  valueGetter: (value) => value.row.keywords_count ?? '—',
  renderCell: (params) => (<LinkRedirect
      location={op?.code}
      path="/domain/keywords/"
      url={params.row.main_domain}
      value={params.row.keywords_count}
    />)
}, {
  field: 'unique_urls_count',
  headerName: t('unique_urls_count'),
  description: t('tooltips.tables.keyword'),
  width: 170,
  type: 'number',
  headerAlign: 'center',
  valueGetter: (value) => value.row.unique_urls_count ?? '—',
  renderCell: (params) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      {params.value}
    </div>)
}, {
  field: 'search_intent',
  headerName: t('Intenciones'),
  description: t('tooltips.tables.search_intent'),
  width: 170,
  type: 'string',
  headerAlign: 'center',
  valueGetter: (value) => {
    const intent = value.row.search_intent;
    return intent ? Object.entries(intent).map(([key, val]) => `${key}: ${val}`).join(', ') : '—';
  }
}, {
  field: 'rank_ranges',
  headerName: t('Rangos'),
  description: t('tooltips.tables.rank_ranges'),
  width: 350,
  type: 'string',
  headerAlign: 'center',
  sortable: false,
  renderCell: (params) => (<div style={{ position: 'relative', left: '-5px', top: '10px' }}>
      <RankRangesWrapper data={params.row.rank_ranges} loading={false} height={100} />
    </div>)
}];


// ******************** IDEAS ********************
export const COLUMNS_NICHE_IDEAS: ColumnsReturnType = (t, op) => [
  //   {
  //   field: 'keyword',
  //   headerName: t('keyword'),
  //   description: t('tooltips.tables.keyword'),
  //   headerAlign: 'left',
  //   flex: 1,
  //   minWidth: 250,
  //   renderCell: (params) => op?.isKeyword ? (<KeywordLink keyword={params.row.keyword} />) : (
  //     <KeywordRedirectLink db={op?.code} keyword={params.row.keyword} />), ...op
  // }, {
  //   field: 'volume',
  //   headerName: t('volume'),
  //   description: t('tooltips.tables.volumen'),
  //   type: 'number',
  //   headerAlign: 'center',
  //   valueGetter: (value) => value.row.keyword_info?.search_volume ?? '—'
  // }, {
  //   field: 'keyword_info.cpc',
  //   headerName: t('cpc'),
  //   headerAlign: 'center',
  //   width: 100,
  //   type: 'number',
  //   description: t('tooltips.tables.cpc'),
  //   valueGetter: (params) => params.row.keyword_info?.cpc ?? '—',
  //   renderCell: (params) => params.row.keyword_info?.cpc ? `$${params.row.keyword_info?.cpc.toFixed(2)}` : '—'
  // }, {
  //   field: 'keyword_info.competition',
  //   headerName: t('competition'),
  //   headerAlign: 'center',
  //   description: t('tooltips.tables.competition'),
  //   valueGetter: (value) => value.row.keyword_info?.competition ?? '—'
  // }, {
  //   field: 'extra.keyword_difficulty',
  //   headerName: t('difficulty'),
  //   headerAlign: 'center',
  //   description: t('tooltips.tables.difficult'),
  //   renderCell: (params) => getComponent(params),
  //   valueGetter: (value) => value.row.extra?.keyword_difficulty
  // }, {
  //   sortable: false,
  //   field: 'search_intent_info.intent',
  //   headerName: t('intent.title'),
  //   headerAlign: 'center',
  //   description: t('tooltips.tables.intent'),
  //   renderCell: (params) => getComponent(params)
  // }, {
  //   sortable: false,
  //   field: 'serp_info.item_types',
  //   headerName: t('features_SERP'),
  //   description: t('tooltips.tables.serps'),
  //   minWidth: 150,
  //   headerAlign: 'center',
  //   renderCell: (params) => getComponent(params),
  //   valueGetter: (value) => value.row.serp_info?.item_types ?? [].toString()
  // }, {
  //   sortable: false,
  //   field: 'keyword_info.history',
  //   headerName: t('history'),
  //   description: t('tooltips.tables.history'),
  //   width: 150,
  //   headerAlign: 'center',
  //   renderCell: (params) => getComponent(params),
  //   valueGetter: (value) => value.row.keyword_info?.history.map((element) => element.volume).join(',') || ''
  // }
  ];

export const COLUMNS_DOMAIN_IDEAS_OVERVIEW: ColumnsReturnType<DomainIdeasResponse['items'][0]> = (t, op) => [{
  field: 'domain',
  headerName: t('domain'),
  description: t('tooltips.tables.keyword'),
  flex: 1,
  width: 350,
  minWidth: 200,
  type: 'string',
  valueGetter: (value) => value.row.domain ?? '—',
  renderCell: (params) => <LinkRedirect location={op?.code} url={params.row.domain} />
}, {
  field: 'traffic',
  headerName: t('traffic'),
  type: 'number',
  minWidth: 50,
  headerAlign: 'center',
  description: t('tooltips.tables.volumen'),
  valueGetter: (params) => (params.row.traffic ? params.row.traffic.toFixed(2) : '—')
}, {
  field: 'trafficCost',
  headerName: t('traffic_cost'),
  type: 'number',
  width: 130,
  headerAlign: 'center',
  description: t('tooltips.tables.volumen'),
  valueGetter: (params) => params.row.trafficCost ? `$${params.row.trafficCost.toFixed(2)}` : '—'
}, {
  field: 'totalKeywords',
  headerName: t('keywords_count'),
  description: t('tooltips.tables.keyword'),
  width: 180,
  type: 'number',
  headerAlign: 'center',
  valueGetter: (value) => value.row.totalKeywords ?? '—',
  renderCell: (params) => (<LinkRedirect
      location={op?.code}
      path="/domain/keywords/"
      url={params.row.domain}
      value={params.row.totalKeywords}
    />)
}, {
  field: 'urls',
  headerName: t('unique_urls_count'),
  description: t('tooltips.tables.keyword'),
  width: 150,
  type: 'number',
  headerAlign: 'center',
  valueGetter: (value) => value.row.urls ?? '—'
}];

// ???????
export const COLUMNS_OPPORTUNITY_KEYWORD: ColumnsReturnType = (t) => [
  {
  field: 'keyword',
  headerName: t('keyword'),
  description: t('tooltips.tables.keyword'),
  flex: 1,
  minWidth: 400,
  renderCell: (params) => <KeywordRedirectLink keyword={params.row.keyword} />
}, {
  field: 'ctr',
  type: 'number',
  headerName: t('ctr'),
  description: t('tooltips.tables.ctr'),
  valueGetter: (value) => value.row.ctr ?? '—',
  renderCell: (value) => `${Number((value.row.ctr as number).toFixed(1))} %`
}, {
  field: 'clicks',
  headerName: t('clicks'),
  description: t('tooltips.tables.clicks'),
  headerAlign: 'center',
  valueGetter: (value) => value.row.clicks ?? '—'
}, {
  field: 'impressions',
  headerName: t('impressions'),
  description: t('tooltips.tables.impressions'),
  flex: 1,
  headerAlign: 'center',
  maxWidth: 100,
  valueGetter: (value) => value.row.impressions ?? '—'
}, {
  field: 'position',
  headerName: t('position'),
  description: t('tooltips.tables.position'),
  flex: 1,
  headerAlign: 'center',
  maxWidth: 100,
  valueGetter: (value) => value.row.position ?? '—',
  renderCell: (value) => (value.row.position != null ? formatPosition(value.row.position) : '—')
}, {
  field: 'contains_keyword',
  headerName: t('contains_keyword'),
  type: 'boolean',
  headerAlign: 'center',
  minWidth: 225,
  valueGetter: (value) => value.row.contains_keyword ?? '—',
  renderCell: (params) => getComponent(params),
  filterOperators: [{
    label: t('contains_keyword'),
    value: 'contains_keyword',
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value === undefined) return null;
      const valueAsBoolean = String(filterItem.value) === 'true';
      return (value): boolean => {
        return Boolean(value.row.contains_keyword) === valueAsBoolean;
      };
    },
    InputComponent: undefined,
    InputComponentProps: { type: 'boolean' },
    getValueAsString: (value: boolean) => `${value}`
  }]
}
];


// ******************** PROJECTS ********************
export const COLUMNS_THIN_CONTENT: ColumnsReturnType = (t) => [{
  field: 'url',
  headerName: t('url'),
  description: t('tooltips.tables.url'),
  flex: 1,
  minWidth: 400,
  renderCell: (params) => getComponent(params)
}, {
  field: 'ctr',
  type: 'number',
  headerName: t('ctr'),
  description: t('tooltips.tables.ctr'),
  valueGetter: (value) => value.row.ctr ?? '—',
  renderCell: (value) => `${Number((value.row.ctr as number).toFixed(1))} %`
}, {
  type: 'number',
  field: 'clicks',
  headerName: t('clicks'),
  description: t('tooltips.tables.clicks'),
  valueGetter: (value) => value.row.clicks ?? '—'
}, {
  type: 'number',
  field: 'impressions',
  headerName: t('impressions'),
  description: t('tooltips.tables.impressions'),
  valueGetter: (value) => value.row.impressions ?? '—'
}, {
  type: 'number',
  field: 'average_position',
  headerName: t('position'),
  description: t('tooltips.tables.position'),
  sortComparator: (v1, v2) => parseInt(v1) - parseInt(v2),
  valueGetter: (value) => value.row.average_position ?? '—',
  renderCell: (value) => value.row.average_position != null ? formatPosition(value.row.average_position) : '—'
}, {
  field: 'is_low_by_clicks',
  headerName: t('detected_problems'),
  description: t('tooltips.tables.detected_problems'),
  minWidth: 250,
  renderCell: (params) => getComponent(params)
}];

export const COLUMNS_CANNIBALIZATION: ColumnsReturnType = (t) => [{
  field: 'keyword',
  headerName: t('keyword'),
  description: t('tooltips.tables.keyword'),
  minWidth: 350,
  renderCell: (params) => <KeywordRedirectLink keyword={params.row.keyword} />
},

  {
    field: 'results',
    headerName: t('it_is_positioned_in'),
    description: t('tooltips.tables.it_is_positioned_in'),
    flex: 1,
    colSpan: 4,
    editable: false,
    renderCell: (params) => <CannibalizeResults {...params.row} />
  }, {
    field: 'impressions',
    headerName: t('impressions'),
    description: t('tooltips.tables.impressions'),
    groupable: true,
    width: 100
  }, {
    field: 'clicks', headerName: t('clicks'), description: t('tooltips.tables.clicks'), groupable: true, width: 60
  }, {
    field: 'position',
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    groupable: true,
    width: 100
  }];



export const COLUMNS_PROJECTS_MANAGE = (t, blurDomains): GridColDef[] => [
  {
  type: 'string',
  field: 'property',
  headerName: t('project'),
  description: t('tooltips.tables.project'),
  minWidth: 250,
  maxWidth: 350,
  sortable: false,
  flex: 1,
  headerAlign: 'left',
  disableColumnMenu: true,
  renderCell: (params) => {
    let domain;
    if (params.row.property.startsWith('sc-domain:')) {
      domain = params.row.property.replace('sc-domain:', '');
    } else {
      try {
        const url = new URL(params.row.property);
        domain = url.hostname;
      } catch (error) {
        domain = params.row.property;
      }
    }
    domain = new URL(`http://${domain}`).hostname;

    return (<Stack direction="row" spacing={2} alignItems="center">
        <img
          src={`https://www.google.com/s2/favicons?sz=32&domain=${domain}`}
          loading="lazy"
          alt="favicon"
          width={32}
          height={32}
          style={{ borderRadius: '8px' }}
        />
        <Typography className={blurDomains ? 'blurred-text' : ''}>
          {truncate(domain)}
        </Typography>
      </Stack>);
  }
}, {
  type: 'string',
  field: 'accountInfo',
  headerName: t('account_info'),
  description: t('tooltips.tables.account'),
  flex: 1,
  minWidth: 250,
  sortable: false,
  headerAlign: 'left',
  disableColumnMenu: true,
  renderCell: (params) => (<div className="flex gap-2">
      <Avatar src={params.row.accountInfo.profile_url} alt={params.row.accountInfo.name} />
      <div className="flex flex-col">
        <Typography>{params.row.accountInfo.name}</Typography>
        <Typography variant="caption" className={blurDomains ? 'blurred-text' : ''}>
          {params.row.accountInfo.email}
        </Typography>
      </div>
    </div>)
}
];

const getDomainName = (url: string) => {
  try {
    const hostname = new URL(url).hostname;
    return hostname.replace(/^www\./, '');
  } catch (error) {
    return url.replace(/^www\./, '');
  }
};

export const COLUMNS_DOMAIN_CONTENT_GAP = (t: any, op: any): GridColDef[] => {
  const baseColumnsBeforeCompetitors: GridColDef[] = [{
    field: 'keyword',
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    flex: 1,
    minWidth: 350,
    headerAlign: 'left',
    renderCell: (params) => <KeywordRedirectLink db={op?.code} keyword={params.row.keyword} />,
    type: 'string'
  }, {
    field: 'search_intent_info.intent',
    sortable: false,
    headerName: t('intent.title'),
    description: t('tooltips.tables.intent'),
    renderCell: (params) => getComponent(params),
    type: 'string'
  }, {
    field: 'you_domain',
    headerName: op?.youDomain || 'Your domain',
    description: t('tooltips.domains.contentgap_your_domain'),
    minWidth: 140,
    headerAlign: 'center' as GridAlignment,
    valueGetter: (value) => {
      const youDomainName = getDomainName(op.youDomain);
      const domainData = value.row.serp_info?.serp.find((d: any) => getDomainName(d.domain) === youDomainName);
      return domainData?.rank_absolute ?? '—';
    },
    cellClassName: (params) => {
      const allRanks = params.row.serp_info?.serp.map((serp: any) => serp.rank_absolute).filter((rank: any) => rank !== null && rank !== undefined);
      if (allRanks.length === 0) return 'domain-cell';
      const lowestRank = Math.min(...allRanks);
      return classNames('domain-cell', params.value === lowestRank ? 'highlight-lowest-domain' : 'domain-positive');
    },
    type: 'number'
  }];

  const competitorColumns = op.domains.map((domain: string, index: number): GridColDef => ({
    field: `domain_${index + 1}`,
    headerName: domain || `Domain competitor ${index + 1}`,
    description: t('tooltips.domains.contentgap_domain_competitor'),
    minWidth: 140,
    headerAlign: 'center' as GridAlignment,
    valueGetter: (value) => {
      const competitorDomainName = getDomainName(domain);
      const domainData = value.row.serp_info?.serp.find((d: any) => getDomainName(d.domain) === competitorDomainName);
      return domainData?.rank_absolute ?? '—';
    },
    cellClassName: (params) => {
      const allRanks = params.row.serp_info?.serp.map((serp: any) => serp.rank_absolute).filter((rank: any) => rank !== null && rank !== undefined);
      if (allRanks.length === 0) return 'domain-cell';
      const lowestRank = Math.min(...allRanks);
      return classNames('domain-cell', params.value === lowestRank ? 'highlight-lowest-domain' : 'domain-positive');
    },
    type: 'number'
  }));

  const baseColumnsAfterCompetitors: GridColDef[] = [{
    field: 'keyword_info.search_volume',
    headerName: t('volume'),
    description: t('tooltips.tables.volumen'),
    headerAlign: 'center' as GridAlignment,
    valueGetter: (value) => formatAmount(value.row.keyword_info?.search_volume) ?? '—',
    type: 'number'
  }, {
    field: 'extra.keyword_difficulty',
    headerName: t('difficulty'),
    description: t('tooltips.tables.difficult'),
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: (value) => value.row.extra?.keyword_difficulty,
    type: 'number'
  }, {
    field: 'keyword_info.cpc',
    headerName: t('cpc'),
    description: t('tooltips.tables.cpc'),
    headerAlign: 'center',
    valueGetter: (value) => value.row.keyword_info?.cpc ? `${value.row.keyword_info.cpc.toFixed(2)}$` : '—',
    type: 'number'
  }, {
    field: 'keyword_info.competition',
    headerName: t('competition'),
    description: t('tooltips.tables.competition'),
    headerAlign: 'center',
    valueGetter: (value) => value.row.keyword_info?.competition ? formatNumber(value.row.keyword_info.competition) : '—',
    type: 'number'
  }, {
    field: 'serp_info.se_results_count',
    headerName: t('results'),
    description: t('tooltips.tables.serps'),
    headerAlign: 'center' as GridAlignment,
    valueGetter: (value) => formatAmount(value.row.serp_info?.se_results_count || 0) ?? '—',
    type: 'number'
  }];

  return baseColumnsBeforeCompetitors.concat(competitorColumns).concat(baseColumnsAfterCompetitors);
};

export const COLUMNS_DOMAIN_KEYWORD_INTENT_TABLE: ColumnsReturnType = (t) => [{
  field: 'name',
  headerName: t('intent.title'),
  description: t('tooltips.tables.intent'),
  flex: 1,
  renderCell: (params) => (<div className="intent-wrap">
      <div className="pin" style={{ backgroundColor: params.row.color }} />
      <Typography>{t(`intent.${params.row.name}`)}</Typography>
    </div>)
}, {
  field: 'result',
  headerName: '',
  headerAlign: 'center',
  hideable: true,
  renderCell: (params) => <Typography color="blue">{params.row.result}</Typography>
}, {
  field: 'keyword', headerName: t('keywords'), description: t('tooltips.tables.keyword'), headerAlign: 'center'
}];

export const COLUMNS_DOMAIN_BLACKLINKS: ColumnsReturnType = (t) => [{
  field: 'domain_from_rank',
  headerName: t('domain_from_rank'),
  description: t('tooltips.domains.blacklinks_rank'),
  headerAlign: 'center'
}, {
  field: 'backlink_spam_score',
  headerName: t('backlink_spam_score'),
  description: t('tooltips.domains.blacklinks_spam_score'),
  headerAlign: 'center'
}, {
  field: 'page_from_title',
  headerName: t('page_from_title'),
  description: t('tooltips.domains.blacklinks_page_from_title'),
  headerAlign: 'center',
  flex: 1,
  minWidth: 300,
  renderCell: (params) => <BacklinkPageFromTitle {...params.row} />
}, {
  field: 'anchor',
  headerName: t('anchor'),
  description: t('tooltips.domains.blacklinks_anchor'),
  headerAlign: 'center',
  flex: 1,
  minWidth: 300,
  renderCell: (params) => (<div className="flex flex-col">
      <Typography>{params.row.anchor}</Typography>
      <KeywordLinkBlank href={params.row.url_to} />
      <div className="flex gap-1 items-center">
        {Array.isArray(params.row.attributes) && params.row.attributes.length > 0 && params.row.attributes.map((item) =>
          <Chip key={item} label={item} />)}
        {params.row.item_type && <Chip label={params.row.item_type} />}
        {params.row.semantic_location && <Chip label={params.row.semantic_location} />}
      </div>
    </div>)
}, {
  field: 'page_from_external_links',
  headerName: t('page_from_external_links'),
  description: t('tooltips.domains.blacklinks_external_links'),
  headerAlign: 'center'
}, {
  field: 'page_from_internal_links',
  headerName: t('page_from_internal_links'),
  description: t('tooltips.domains.blacklinks_internal_links'),
  headerAlign: 'center'
}, {
  field: 'first_seen',
  headerName: t('first_seen'),
  description: t('tooltips.domains.blacklinks_first_seen'),
  headerAlign: 'center',
  renderCell: (params) => (<Typography className={classNames('seen-new', params.row.is_new ? 'active' : '')}>
      {formatDateNow(params.row.first_seen)}
    </Typography>)
}, {
  field: 'last_seen',
  headerName: t('last_seen'),
  description: t('tooltips.domains.blacklinks_last_seen'),
  minWidth: 120,
  headerAlign: 'center',
  renderCell: (params) => (<Typography className={classNames('seen-lost', params.row.is_lost ? 'active' : '')}>
      {formatDateNow(params.row.last_seen)}
    </Typography>)
}];

export const COLUMNS_ACCOUNT_CONNECTIONS: ColumnsReturnType = (t) => [{
  field: 'name',
  headerName: t('account'),
  description: t('tooltips.tables.connection_account'),
  flex: 1,
  renderCell: (params) => (<Stack direction="row" spacing={2}>
      <Avatar src={params.row.profile_url} alt={params.row.name} />
      <Stack>
        <Typography>{params.row.name}</Typography>
        <Typography variant="caption" className="table-connections-email">
          {params.row.email}
        </Typography>
      </Stack>
    </Stack>)
}, {
  field: 'view_properties',
  headerName: t('view_properties'),
  description: t('tooltips.tables.connection_properties'),
  pinnable: false,
  width: 130,
  headerAlign: 'center',
  renderCell: () => (<Button>
      <VisibilityIcon />
    </Button>)
}];

export const COLUMNS_ACCOUNT_BILLINGS: ColumnsReturnType<BillingsResponse> = (t) => [{
  field: 'amount',
  headerName: t('amount'),
  flex: 1,
  type: 'number',
  headerAlign: 'center',
  valueGetter: (params) => params.row.amount ?? '—',
  renderCell: (params) => params.row.amount
}, {
  field: 'currency',
  headerName: t('currency'),
  flex: 1,
  type: 'string',
  headerAlign: 'center',
  valueGetter: (params) => params.row.currency ?? '—',
  renderCell: (params) => params.row.currency
}, {
  field: 'status',
  headerName: t('status'),
  flex: 1,
  type: 'string',
  headerAlign: 'center',
  valueGetter: (params) => params.row.status ?? '—',
  renderCell: (params) => params.row.status ?? '—'
}, {
  field: 'date',
  headerName: t('date'),
  flex: 1,
  type: 'date',
  headerAlign: 'center',
  valueGetter: (params) => {
    return params.row.date ? new Date(Number(params.row.date) * 1000) : new Date();
  },
  renderCell: (params) => {
    const date = params.row.date ? new Date(Number(params.row.date) * 1000) : new Date();
    return format(date, 'dd/MM/yyyy');
  }
}];

/**
 *
 * @param col column of the table
 * @param value the value to render
 * @returns the component to be painted depending of the type of column name
 */
const getComponent: RenderCellTable = (params) => {
  const value = params.row[params.field];
  switch (params.field) {
    case CONSTANT.COLUMN_DIFFICULTY_NAME:
      return <KeywordDifficulty value={params.row.extra?.keyword_difficulty} />;
    case CONSTANT.COLUMN_HISTORY_TREND_NAME:
      return <HistoryTrendChart data={value} />;
    case CONSTANT.COLUMN_HISTORY_NAME:
      return <HistoryTrendChart data={params.row.keyword_info?.history} />;
    case CONSTANT.COLUMN_POSITION_NAME:
      return <KeywordPosition data={{ position: value, prevPos: params.row.prevPos }} />;
    case CONSTANT.COLUMN_DOMAIN_POSITION_NAME:
      return (<KeywordPosition
          data={{
            position: params.row.serp_info.serp[0].rank_absolute,
            prevPos: params.row.serp_info.serp[0].previous_rank_absolute
          }}
        />);
    case CONSTANT.COLUMN_KEYWORDS_NAME:
      return <KeywordLink keyword={value} />;
    case CONSTANT.COLUMN_URLS_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_COMPARE_URLS_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_DOMAIN_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_INTENT_INFO_NAME:
      return <ContainerIntents data={params.row.search_intent_info} />;
    case CONSTANT.COLUMN_ITEM_TYPES_NAME:
      return <KeywordFeatures data={params.row.serp_info?.item_types} />;
    case CONSTANT.COLUMN_SERP_RANK_ABSOLUTE:
      return <ResultRankAbsolute position={params.row.serp_info?.serp?.rank_absolute} />;
    case CONSTANT.COLUMN_SERP_URL:
      return <SerpUrl url={params.row.url} domain={params.row.domain} />;
    case CONSTANT.COLUMN_CONTAINS_KEYWORD:
      return <ContainsKeyword value={params.row.contains_keyword} />;
    case CONSTANT.IS_LOW_BY_CLICKS:
      return (<IsLowThinContent
          data={{
            is_low_by_clicks: params.row.is_low_by_clicks,
            is_low_by_impressions: params.row.is_low_by_impressions,
            is_low_by_ctr: params.row.is_low_by_ctr
          }}
        />);
    default:
      return value;
  }
};
