import { format } from 'date-fns';
import * as CONSTANT from './../Utils/Constants';
import IsLowThinContent from '../IsLowThinContent';
import { BillingsResponse } from '../../types/common.type';
import { ColumnsReturnType, DomainBulkResponse, DomainIdeasResponse, RenderCellTable } from '../../types';
import {
  classNames, formatAmount, formatCurrencyByLocation, formatDateNow, formatNumber, roundNumberWithDecimals, truncate
} from './Utils';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Button, Chip, Stack, Typography } from '@mui/material';

// COMPONENTS
import SerpUrl from '../SerpUrl/SerpUrl';
import LinkRedirect from '../Links/Redirect';
import KeywordLinkBlank from '../KeywordLinkBlank';
import KeywordLink from '../../components/KeywordLink';
import ResultRankAbsolute from '../ResultRankAbsolute';
import KeywordRedirectLink from '../KeywordRedirectLink';
import ContainerIntents from '../KeywordIntent/Container';
import BacklinkPageFromTitle from '../Backlinks/PageFromTitle';
import ContainsKeyword from '../ContainsKeyword/ContainsKeyword';
import KeywordFeatures from '../KeywordFeatures/KeywordFeatures';
import KeywordDifficulty from '../../components/KeywordDifficulty';
import HistoryTrendChart from '../../components/HistoryTrendChart';
import CannibalizeResults from '../CannibalizeResults/CannibalizeResults';
import KeywordPosition from '../../components/KeywordPosition/KeywordPosition';
import OverviewRedirectLink from '../OverviewRedirectLink/KeywordRedirectLink';
import RankRangesWrapper from '../Recharts/RankRangesWrapper/RankRangesWrapper';
import '../../pages/domain/Comparator.scss';



export const COLUMN_KEYWORD_INFO_COMPETITION = (t, options): GridColDef<any> => ({
  field: 'keyword_info.competition',
  headerName: t('competition'),
  description: t('tooltips.tables.competition'),
  type: 'number',
  valueGetter: (value, row) => row.keyword_info?.competition, ...options
});

export const COLUMN_KEYWORD_INFO_CPC = (t, options): GridColDef<any> => ({
  field: 'keyword_info.cpc',
  headerName: t('cpc'),
  description: t('tooltips.tables.cpc'),
  type: 'number',
  valueGetter: (value, row) => row.keyword_info?.cpc,
  renderCell: (params) => formatCurrencyByLocation(params, options), ...options
});

export const COLUMN_KEYWORD_KEYWORD = (t, options): GridColDef<any> => ({
  field: 'keyword.keyword',
  headerName: t('keyword'),
  description: t('tooltips.tables.keyword'),
  type: 'string',
  flex: 1,
  width: 350,
  minWidth: 200,
  valueGetter: (value, row) => row.keyword,
  renderCell: (params) => options?.isKeyword ? (<KeywordLink keyword={params.row.keyword} />) : (
    <KeywordRedirectLink db={options?.code} keyword={params.row.keyword} />), ...options
});  

export const COLUMN_ETV = (t, options): GridColDef<any> => ({
  field: 'etv', 
  width: 150, 
  headerName: t('etv'), 
  headerAlign: 'right', 
  valueGetter: (value, row) => row?.etv
});

export const COLUMN_EXTRA_KEYWORD_DIFFICULTY = (t, options): GridColDef<any> => ({
  field: 'extra.keyword_difficulty',
  headerName: t('difficulty'),
  description: t('tooltips.tables.difficult'),
  type: 'number',
  valueGetter: (value, row) => row.extra?.keyword_difficulty,
  renderCell: (value) => getComponent(value), ...options
});

export const COLUMN_KEYWORD_INFO_HISTORY = (t, options): GridColDef<any> => ({
  sortable: false,
  field: 'keyword_info.history',
  headerName: t('history'),
  description: t('tooltips.tables.history'),
  width: 150,
  headerAlign: 'center',
  renderCell: (value) => getComponent(value)
});

export const COLUMN_KEYWORD_INFO_SEARCH_VOLUME = (t, options): GridColDef<any> => ({
  field: 'keyword_info.search_volume',
  headerName: t('volume'),
  description: t('tooltips.tables.volumen'),
  type: 'number',
  width: 100,
  valueGetter: (value, row) => row.keyword_info?.search_volume, ...options
});

export const COLUMN_PRICE = (t, options): GridColDef<any> => ({
  field: 'price',
  headerName: t('price'),
  description: t('tooltips.tables.price_traffic'),
  type: 'number',
  headerAlign: 'right',
  valueGetter: (value, row) => row.serp_info.serp[0].traffic_cost,
  renderCell: (params) => formatCurrencyByLocation(params, options), ...options
});

export const COLUMN_RANK_ABSOLUTE = (t, options): GridColDef<any> => ({
  field: 'rank_absolute',
  headerName: t('position'),
  description: t('tooltips.tables.position'),
  headerAlign: 'center',
  align: 'center',
  maxWidth: 80,
  valueGetter: (value, row) => row.rank_absolute,
  renderCell: (value) => value.row.previous_rank_absolute > 0 ? (<KeywordPosition
      data={{
        position: value.row.rank_absolute, prevPos: value.row.previous_rank_absolute
      }}
/>) : (value.row.rank_absolute)
});

export const COLUMN_SEARCH_INTENT_INFO = (t, options): GridColDef<any> => ({
  sortable: false,
  field: 'search_intent_info.intent',
  headerName: t('intent.title'),
  description: t('tooltips.tables.intent'),
  headerAlign: 'right',
  valueGetter: (value, row) => row.search_intent_info.main_intent,
  renderCell: (value) => getComponent(value), ...options
});

export const COLUMN_SERP_INFO = (t, options): GridColDef<any> => ({
  sortable: false,
  field: 'serp_info.item_types',
  headerName: t('features_SERP'),
  description: t('tooltips.tables.serps'),
  minWidth: 150,
  headerAlign: 'right',
  valueGetter: (value, row) => row.serp_info?.item_types,
  renderCell: (value) => getComponent(value), ...options
});

export const COLUMN_SERP_INFO_SERP_POSITION = (t, options): GridColDef<any> => ({
  field: 'serp_info.serp.position',
  headerName: t('position'),
  description: t('tooltips.tables.position'),
  type: 'number',
  headerAlign: 'right',
  valueGetter: (value, row) => row.serp_info.serp[0].rank_absolute,
  renderCell: (params) => getComponent(params), ...options
});

export const COLUMN_SERP_INFO_SERP_URL = (t, options): GridColDef<any> => ({
  field: 'serp_info.serp.url',
  sortable: false,
  headerName: t('url'),
  description: t('tooltips.tables.url'),
  flex: 1,
  minWidth: 350,
  headerAlign: 'left',
  renderCell: (value) => <KeywordLinkBlank href={value.row.serp_info.serp[0].url} />, ...options
});

export const COLUMN_TRAFFIC = (t, options): GridColDef<any> => ({
  field: 'traffic',
  headerName: t('traffic'),
  description: t('tooltips.tables.price_traffic'),
  type: 'number',
  headerAlign: 'right',
  valueGetter: (value, row) => row.serp_info.serp[0].etv,
  renderCell: (params) => roundNumberWithDecimals(params.row.serp_info.serp[0].etv, 2), ...options
});

export const COLUMN_TRAFFIC_COST = (t, options): GridColDef<any> => ({
  field: 'traffic_cost',
  width: 150,
  headerName: t('traffic_cost'),
  description: t('tooltips.tables.traffic_cost'),
  type: 'number',
  headerAlign: 'right',
  valueGetter: (value, row) => row?.traffic_cost,
  renderCell: (params) => formatCurrencyByLocation(params, options)
});

export const COLUMN_URL = (t, options): GridColDef<any> => ({
  field: 'url',
  headerName: t('url'),
  description: t('tooltips.tables.url'),
  flex: 1,
  minWidth: 350,
  renderCell: (params) => (
    <OverviewRedirectLink location={options?.code} domain={params.row.domain} url={params.row.url} />)
});

/**
 *
 * @param col column of the table
 * @param value the value to render
 * @returns the component to be painted depending of the type of column name
 */
const getComponent: RenderCellTable = (params) => {
  const value = params.row[params.field];
  switch (params.field) {
    case CONSTANT.COLUMN_DIFFICULTY_NAME:
      return <KeywordDifficulty value={params.row.extra?.keyword_difficulty} />;
    case CONSTANT.COLUMN_HISTORY_TREND_NAME:
      return <HistoryTrendChart data={value} />;
    case CONSTANT.COLUMN_HISTORY_NAME:
      return <HistoryTrendChart data={params.row.keyword_info?.history} />;
    case CONSTANT.COLUMN_POSITION_NAME:
      return <KeywordPosition data={{ position: value, prevPos: params.row.prevPos }} />;
    case CONSTANT.COLUMN_DOMAIN_POSITION_NAME:
      return (<KeywordPosition
          data={{
            position: params.row.serp_info.serp[0].rank_absolute,
            prevPos: params.row.serp_info.serp[0].previous_rank_absolute
          }}
        />);
    case CONSTANT.COLUMN_KEYWORDS_NAME:
      return <KeywordLink keyword={value} />;
    case CONSTANT.COLUMN_URLS_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_COMPARE_URLS_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_DOMAIN_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_INTENT_INFO_NAME:
      return <ContainerIntents data={params.row.search_intent_info} />;
    case CONSTANT.COLUMN_ITEM_TYPES_NAME:
      return <KeywordFeatures data={params.row.serp_info?.item_types} />;
    case CONSTANT.COLUMN_SERP_RANK_ABSOLUTE:
      return <ResultRankAbsolute position={params.row.serp_info?.serp?.rank_absolute} />;
    case CONSTANT.COLUMN_SERP_URL:
      return <SerpUrl url={params.row.url} domain={params.row.domain} />;
    case CONSTANT.COLUMN_CONTAINS_KEYWORD:
      return <ContainsKeyword value={params.row.contains_keyword} />;
    case CONSTANT.IS_LOW_BY_CLICKS:
      return (<IsLowThinContent
          data={{
            is_low_by_clicks: params.row.is_low_by_clicks,
            is_low_by_impressions: params.row.is_low_by_impressions,
            is_low_by_ctr: params.row.is_low_by_ctr
          }}
        />);
    default:
      return value;
  }
};