import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDomainsData } from '../../hooks/useDomainsData';
import { useAccessToken } from '../../hooks/useAccessToken';
import useRefreshQueries from '../../hooks/useRefreshQueries';
import { COLUMNS_DOMAIN_COMPETITORS_KEYWORDS } from '../../components/Utils/TableConstants';

import { Grid, Typography } from '@mui/material';
import Search from '../../components/Search';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import { useSearchCache } from '../../context/SearchCacheContext';

const COMPETITORS_CACHE_KEY = 'competitors_search';

export const Competitors = () => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const { location, handleQuery, handleLocation } = useRefreshQueries();
  const {
    competitors,
    loadingCompetitors,
    fetchDomainCompetitors,
    fetchDomainCompetitorWithQueries
  } = useDomainsData('competitors');

  const { getPagination, updatePagination } = useSearchCache();
  const context = 'competitors';
  const [pagination, setPagination] = useState(() => getPagination(context));

  const handleSearchBtn = async (search: string) => {
    if (search.length === 0) return;
    handleQuery(search);
    const data = { location, domain: search };
    await fetchDomainCompetitors(data);
  };

  const handlePaginationChange = (newPagination) => {
    setPagination(newPagination);
    updatePagination(context, newPagination);
  };

  useEffect(() => {
    fetchDomainCompetitorWithQueries();
  }, [token, fetchDomainCompetitorWithQueries]);

  useEffect(() => {
    const savedPagination = getPagination(context);
    setPagination(savedPagination);
  }, []);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Search
            onCountry={handleLocation}
            onSearch={handleSearchBtn}
            loading={loadingCompetitors}
            cacheKey={COMPETITORS_CACHE_KEY}
          />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Typography className="mb-5">{t('organic_competitors')}</Typography>
          <TableComponent
            rows={competitors}
            loading={loadingCompetitors}
            columns={COLUMNS_DOMAIN_COMPETITORS_KEYWORDS(t)}
            paginationModel={pagination}
            onPaginationModelChange={handlePaginationChange}
            initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};