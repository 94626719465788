import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useExportData from '../../hooks/useExportData';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useKeywordsData } from '../../hooks/useKeywordsData';
import useRefreshQueries from '../../hooks/useRefreshQueries';
import * as CONSTANTS from '../../components/Utils/Constants';
import usePaginationModel from '../../hooks/usePaginationModel';
import { COLUMNS_KEYWORD_ANALYSIS } from '../../components/Utils/TableConstants';
import { KeywordsFiltersProvider, useKeywordsFiltersContext } from '../../context/KeywordsFilterContext';
import { KeywordsIdeasCacheProvider, useKeywordsIdeasCache } from '../../context/KeywordsIdeasCacheContext';
import { Grid, Typography } from '@mui/material';
import Search from '../../components/Search';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import KeywordMetadata from '../../components/KeywordMetadata';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import ButtonExportExcel from '../../components/Buttons/ExportExcel';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import KeywordsFilters from '../../components/Filters/Keywords/KeywordsFilter';

const KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT = 'keywordsIdeasSearch';
const FILTERS_STORAGE_KEY = 'keywords_ideas_filters';

const ContainerPage: React.FC = () => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const { isLoading, fetchExportKeywordsIdeasOverview } = useExportData();
  const { query, location, handleLocation, handleQuery } = useRefreshQueries(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT);
  const { keywords, loadingKeywords, fetchKeywords, fetchKeywordsIdeasWithQueries } = useKeywordsData(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT);

  const {
    modeFilter, handleModeFilter, handleSearchKeywords, handleSearchSpecificFilters,
    selected, cpcOfSearches, volumeOfSearches, numOfKeywords, differenceOfSearches
  } = useKeywordsFiltersContext();

  const {
    updateKeywordsIdeasCache, updateLocation, getKeywordsIdeasCache, getPagination, updatePagination
  } = useKeywordsIdeasCache();

  const cachedPagination = getPagination(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT);
  const { paginationModel, paginatedMemoized, handleSortModelChange, handlePaginationChange, queryOptions} = usePaginationModel(cachedPagination);

  const [initialRenderDone, setInitialRenderDone] = useState(false);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [simulationMode, setSimulationMode] = useState(true);

  const buildData = useCallback((keywords: string) => {
    const mode = CONSTANTS.FILTER_MODE_MAPPING[modeFilter] || modeFilter;
    const specificFilters = handleSearchSpecificFilters();
    const searchKeywords = handleSearchKeywords();
    const sortModel = queryOptions.sortModel || [];

    return {
      location,
      keywords,
      mode,
      ...paginatedMemoized,
      ...specificFilters,
      ...searchKeywords,
      // sorting: sortModel.length > 0 ? sortModel[0].sort : null,
      // sortField: sortModel.length > 0 ? sortModel[0].field : null,
    };
  }, [location, modeFilter, paginatedMemoized, handleSearchKeywords, handleSearchSpecificFilters, queryOptions.sortModel]);

  const fetchKeywordsMock = useCallback(
    async (data: any) => {
      return new Promise((resolve) => setTimeout(resolve, 500));
    },
    []
  );

  const refreshData = useCallback(
    async (keywords: string) => {
      if (!keywords) return;
      const data = buildData(keywords);
      if (simulationMode) {
        await fetchKeywordsMock(data);
      } else {
        await fetchKeywords(data);
      }
    },
    [buildData, fetchKeywords, fetchKeywordsMock, simulationMode]
  );

  const handleSearchBtn = async (search: string) => {
    if (!search) return;
    handleQuery(search);
    updateKeywordsIdeasCache(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT, search);
    await refreshData(search);
  };

  const handleExportBtn = async () => {
    const cachedQuery = getKeywordsIdeasCache(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT);
    if (!cachedQuery) return;
    const data = buildData(cachedQuery);
    await fetchExportKeywordsIdeasOverview({ ...data, title: 'Ideas' });
  };

  useEffect(() => {
    if (!isFirstFetch) {
      fetchKeywordsIdeasWithQueries();
    }
    setIsFirstFetch(false);
  }, [token]);

  useEffect(() => {
    if (!isFirstFetch && modeFilter && query.length > 0) {
      handlePaginationChange({ ...paginationModel, page: 0 });
    }
  }, [modeFilter]);

  useEffect(() => {
    if (initialRenderDone) {
      const cachedQuery = getKeywordsIdeasCache(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT);
      if (cachedQuery) {
        handleSearchBtn(cachedQuery);
      }
    }
  }, [paginationModel]);

  useEffect(() => {
    setInitialRenderDone(true);
  }, []);

  useEffect(() => {
    if (initialRenderDone) {
      updatePagination(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT, paginationModel);
    }
  }, [paginationModel, initialRenderDone]);

  useEffect(() => {
    if (initialRenderDone && !isFirstFetch) {
      const cachedQuery = getKeywordsIdeasCache(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT);
      if (cachedQuery) {
        handleSearchBtn(cachedQuery);
      }
    }
  }, [
    selected, cpcOfSearches, volumeOfSearches, numOfKeywords, differenceOfSearches, initialRenderDone
  ]);

  useEffect(() => {
    if (simulationMode && initialRenderDone) {
      setSimulationMode(false);
    }
  }, [simulationMode, initialRenderDone]);

  const handleSortModelChangeWithLog = (sortModel) => {
    handleSortModelChange(sortModel);
    if (query) {
      refreshData(query);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const q = params.get('q');
    const db = params.get('db');
    
    if (q && db) {
      fetchKeywordsIdeasWithQueries();
    }
  }, [fetchKeywordsIdeasWithQueries]);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12}>
        <PaperItem className="flex-col gap-2 boxShadow" sx={{ p: 3 }}>
          <Search
            cacheKey={KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT}
            loading={loadingKeywords}
            onSearch={handleSearchBtn}
            onCountry={(code: number) => {
              handleLocation(code);
              updateLocation(KEYWORDS_IDEAS_PAGE_CACHE_CONTEXT, code);
            }}
          />
          <div className="flex flex-col gap-2">
            <ButtonGroup current={modeFilter} onChange={handleModeFilter} suggestions={CONSTANTS.ALL_FILTER_MODE_IDEAS} />
            <div className="flex gap-2 w-full items-center">
              <KeywordsFilters />
            </div>
          </div>
        </PaperItem>
      </Grid>
      <Grid item xs={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <div className="flex flex-col gap-2">
            <CustomTooltip content={t('tooltips.keywords.keyword_ideas_title')}>
              <Typography>{t('keyword_ideas')}</Typography>
            </CustomTooltip>
            <div className="flex items-center justify-between">
              <KeywordMetadata metadata={keywords} />
              <ButtonExportExcel isLoading={isLoading} onClick={handleExportBtn} totalKeywords={keywords.totalKeywords} query={query} />
            </div>
          </div>
          <TableComponent
            rows={keywords.items}
            rowCount={keywords.totalKeywords}
            columns={COLUMNS_KEYWORD_ANALYSIS(t, { code: location })}
            loading={loadingKeywords}
            sortingMode="server"
            paginationMode="server"
            paginationModel={paginationModel}
            hideFooterPagination={loadingKeywords}
            onSortModelChange={handleSortModelChangeWithLog}
            onPaginationModelChange={handlePaginationChange}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};

export const KeywordsIdeasPage: React.FC = () => (
  <KeywordsFiltersProvider storageKey={FILTERS_STORAGE_KEY}>
    <KeywordsIdeasCacheProvider>
      <ContainerPage />
    </KeywordsIdeasCacheProvider>
  </KeywordsFiltersProvider>
);