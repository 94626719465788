import React, { useState } from 'react';
import './TableComponent.scss';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { DataGridPremium} from '@mui/x-data-grid-premium';
import { GridSlotsComponent } from '@mui/x-data-grid';
import {
  DataGridPropsWithDefaultValues,
  DataGridPropsWithoutDefaultValue,
} from '@mui/x-data-grid/internals'


const processColumns = (columns) => {
  return columns.map((column) => {
    const customRenderer = column.renderCell || ((params) => String(params.value));
    return {
      ...column,
      renderCell: (params: GridRenderCellParams) => {
        if(params.formattedValue===null){
          return '—'
        }
        const cellValue = customRenderer(params);
        return (
          <div>{cellValue}</div>
        )

      }
    }
  })
}

const showSkeleton = () => {
  return (
    <Box px={2} py={1}>
      {[...Array(10)].map((_, index) => (
        <Skeleton key={index} animation="wave" height={40} sx={{ mb: 1 }} />
      ))}
    </Box>
  )
}

interface Props extends Partial<DataGridPropsWithDefaultValues>, Omit<DataGridPropsWithoutDefaultValue, 'rows'> {
  rows: any[] | undefined | null;
  blurTitle?: boolean;
  overlayPercentages?: number[];
}

// const TableComponent = ({columns, rows, loading}) => {
const TableComponent: React.FC<Props> = ({ rows, columns, slots, loading, blurTitle, overlayPercentages, ...props }) => {
  const { t } = useTranslation();

  if (loading) {
    return showSkeleton();
  }
  
  if (!rows) return null;


  // Automatically add IDs to rows only if they don't have one
  rows = rows.map((row, index) => ({
    ...row,
    id: row.id ?? index + 1
  }));


  return (
    <DataGridPremium 
      autoHeight
      initialState={
        { 
          pagination: { paginationModel: { pageSize: 10 } }
        }
      }
      rows={rows} 
      columns={processColumns(columns)} 
      loading={loading}
      localeText={{
        noRowsLabel: t('no_rows'),
        MuiTablePagination: {
          labelRowsPerPage: `${t('row_per_page')}:`,
        },
      }}

      getRowClassName={() => 'paxton-table--row'}
      getCellClassName={(params) => {
        return params.field.includes("keyword")?"cellLeft":"cellRight"
      }}

      ignoreDiacritics={true}
      disableColumnMenu={true}

      {...props}
      pagination 
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100]}
      
      sortingMode='server'
      sortingOrder={['asc', 'desc']}
      
      hideFooter={rows.length<10}
    />
  )

}

export default TableComponent;